document.addEventListener('DOMContentLoaded', () => {
    const headerSearch = document.querySelector('#header-search');
    const searchCloseButtons = document.querySelectorAll('.search-modal-background, .search-close-button');

    const htmlTag = document.getElementsByTagName('html')[0];
    const headerTag = document.getElementsByTagName('header')[0];

    if(headerSearch) {
        headerSearch.addEventListener('click', () => {
            htmlTag.classList.toggle('modal-opened');
            headerTag.classList.toggle('search-opened');
        });
    }

    if(searchCloseButtons.length > 0) {
        searchCloseButtons.forEach((item) => {
            item.addEventListener('click', function(event){
                htmlTag.classList.remove('modal-opened');
                headerTag.classList.remove('search-opened');
            });
        });
    }

    const faqWrapper = document.querySelector('.faq-wrapper');
    const faqPopUp = document.querySelector('.faq-pop-up');
    const faqCloseButtons = document.querySelectorAll('.faq-modal-background, .faq-close-button');

    if (faqWrapper) {
        const faqItems = faqWrapper.querySelectorAll('.faq-item');

        if(faqItems.length > 0) {
            faqItems.forEach((item) => {
                item.addEventListener('click', function () {
                    let faqTitle = item.querySelector('.faq-item-title').innerHTML;
                    let faqContent = item.querySelector('.faq-item-content').innerHTML;

                    document.querySelector('.faq-pop-up-title').innerHTML = faqTitle;
                    document.querySelector('.faq-pop-up-content').innerHTML = faqContent;

                    htmlTag.classList.toggle('modal-opened');
                    faqPopUp.classList.toggle('faq-opened');
                });
            });
        }
    }

    if(faqCloseButtons.length > 0) {
        faqCloseButtons.forEach((item) => {
            item.addEventListener('click', function(event){
                htmlTag.classList.remove('modal-opened');
                faqPopUp.classList.remove('faq-opened');
            });
        });
    }
});