document.addEventListener('DOMContentLoaded', () => {
    let mobileAccordionItem = document.querySelectorAll('.mobile-accordion');

    if(mobileAccordionItem.length > 0) {
        mobileAccordionItem.forEach((item) => {
            item.querySelector('.footer-title').addEventListener('click', function(){
                item.classList.toggle('active');
            });
        });
    }


    let accordionItem = document.querySelectorAll('.accordion-wrapper');

    if(accordionItem.length > 0) {
        accordionItem.forEach((item) => {
            item.querySelector('.accordion-title').addEventListener('click', function(){
                item.classList.toggle('active');

                let content = item.querySelector('.accordion-content');
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + 'px';
                }
            });
        });
    }

    let expandedButtonTable = document.querySelectorAll('.custom-column-table');

    if(expandedButtonTable.length > 0) {
        expandedButtonTable.forEach((item) => {
            item.querySelector('.expand-table-button').addEventListener('click', function(){
                item.classList.toggle('active');

                if (item.offsetHeight > 425) {
                    item.style.maxHeight = '425px';
                } else {
                    item.style.maxHeight = item.scrollHeight + 'px';
                }
            });
        });
    }

    let expandedButtonTranscript = document.querySelectorAll('.transcript-content');

    if(expandedButtonTranscript.length > 0) {
        expandedButtonTranscript.forEach((item) => {
            item.querySelector('.expand-transcript-button').addEventListener('click', function(){
                item.classList.toggle('active');

                if (item.offsetHeight > 500) {
                    item.style.maxHeight = '500px';
                } else {
                    item.style.maxHeight = item.scrollHeight + 'px';
                }
            });

            if (item.offsetHeight < 500) {
                item.querySelector('.expand-transcript-button').classList.add('hide-button');
            }
        });
    }
});
