/* * * * * * * * * * * * * * * * *
 * Pagination
 * javascript page navigation
 * * * * * * * * * * * * * * * * */

window.Pagination = {

    code: '',

    // --------------------
    // Utility
    // --------------------

    // converting initialize data
    Extend: function(data) {
        data = data || {};
        Pagination.size = data.size || 300;
        Pagination.page = data.page || 1;
        Pagination.step = data.step || 3;
    },

    // add pages by number (from [s] to [f])
    Add: function(s, f) {
        for (var i = s; i < f; i++) {
            Pagination.code += '<a>' + i + '</a>';
        }
    },

    // add last page with separator
    Last: function() {
        Pagination.code += '<a class="dots">...</a><a>' + Pagination.size + '</a>';
    },

    // add first page with separator
    First: function() {
        Pagination.code += '<a>1</a><a class="dots">...</a>';
    },

    // --------------------
    // Handlers
    // --------------------

    // change page
    Click: function() {
        Pagination.page = +this.innerHTML;
        Pagination.Start();
    },

    // previous page
    Prev: function() {
        Pagination.page--;
        if (Pagination.page < 1) {
            Pagination.page = 1;
        }
        Pagination.Start();
    },

    // next page
    Next: function() {
        Pagination.page++;
        if (Pagination.page > Pagination.size) {
            Pagination.page = Pagination.size;
        }
        Pagination.Start();
    },



    // --------------------
    // Script
    // --------------------

    // binding pages
    Bind: function() {
        var a = Pagination.e.getElementsByTagName('a');
        for (var i = 0; i < a.length; i++) {
            if (+a[i].innerHTML === Pagination.page) a[i].className = 'active';
            a[i].addEventListener('click', Pagination.Click, false);
        }
    },

    // write pagination
    Finish: function() {
        Pagination.e.innerHTML = Pagination.code;
        Pagination.code = '';
        Pagination.Bind();
    },

    // find pagination type
    Start: function() {
        if(Pagination.page > 1 && Pagination.page < Pagination.size) {
            Pagination.prevButton.classList.remove('button-disable');
            Pagination.nextButton.classList.remove('button-disable');
        } else if (Pagination.page == Pagination.size) {
            Pagination.nextButton.classList.add('button-disable');
            Pagination.prevButton.classList.remove('button-disable');
        } else  {
            Pagination.prevButton.classList.add('button-disable');
            Pagination.nextButton.classList.remove('button-disable');
        }


        if (Pagination.size < Pagination.step * 2 + 6) {
            Pagination.Add(1, Pagination.size + 1);
        }
        else if (Pagination.page < Pagination.step * 2 + 2) {
            Pagination.Add(1, Pagination.step * 2 + 2);
            Pagination.Last();
        }
        else if (Pagination.page > (Pagination.size - Pagination.step * 2) - 1) {
            Pagination.First();
            Pagination.Add(Pagination.size - Pagination.step * 2, Pagination.size + 1);
        }
        else {
            Pagination.First();
            Pagination.Add(Pagination.page - Pagination.step, Pagination.page + Pagination.step + 1);
            Pagination.Last();
        }
        Pagination.Finish();
    },



    // --------------------
    // Initialization
    // --------------------

    // binding buttons
    Buttons: function(e) {
        var nav = e.querySelectorAll('.pagination-button');
        nav[0].addEventListener('click', Pagination.Prev, false);
        nav[1].addEventListener('click', Pagination.Next, false);
        Pagination.prevButton = nav[0];
        Pagination.nextButton = nav[1];
    },

    // create skeleton
    Create: function(e) {

        var html = [
            e.querySelector('.previous-button').outerHTML, //previous-button
            '<div class="is-flex pagination-list" data-label="'+ e.dataset.label +' ' + e.dataset.total + '"></div>',  // pagination container
            e.querySelector('.next-button').outerHTML //next-button
        ];

        e.innerHTML = html.join('');
        Pagination.e = e.querySelector('.pagination-list');
        Pagination.Buttons(e);
    },

    // init
    Init: function(e, data) {
        Pagination.Extend(data);
        Pagination.Create(e);
        Pagination.Start();
    }
};



/* * * * * * * * * * * * * * * * *
* Initialization
* * * * * * * * * * * * * * * * */

var init = function() {
    paginationSelector = document.querySelector('.pagination');
    if(paginationSelector) {
        Pagination.Init(paginationSelector, {
            size: parseInt(paginationSelector.dataset.total), // pages size
            page: parseInt(paginationSelector.dataset.active),  // selected page
            step: 1   // pages before and after current
        });
    }
};

document.addEventListener('DOMContentLoaded', init, false);

