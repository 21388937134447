import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {

    SetCategoriesDropdown();
    SetYearsDropdown();

    const categorizedSearchPagination = document.querySelector('.js-categorized-search-pagination');
    const searchButton = document.querySelector('.js-search-button');

    if (categorizedSearchPagination) {

        let loader = document.querySelector('#loader');
        let buttons = document.querySelectorAll('.pagination-button, .pagination-list');
        const renderingPageId = document.querySelector('#pageID')?.value;

        buttons.forEach(button => {
            button.addEventListener('click', function (e) {

                let urlParameterPage = new URL(location.href).searchParams.get("page");

                if (urlParameterPage != Pagination.page) {
                    loader.removeAttribute("hidden");

                    let selectedCategory = new URL(location.href).searchParams.get("category");
                    let selectedYear = new URL(location.href).searchParams.get("year");

                    $.ajax({
                        type: 'GET',
                        url: '/api/feature/eurobankaedak/categorizedsearch/categorizedsearchresults',
                        data: { category: selectedCategory, year: selectedYear, contextItemId: renderingPageId, page: Pagination.page },
                        contentType: 'application/json; charset=utf-8',
                    })
                        .done(resp => {
                            let dataForUrl = {
                                category: selectedCategory,
                                year: selectedYear,
                                page: Pagination.page,
                            };
                            storeToUrl(dataForUrl);

                            $('.search-results-wrapper').html(resp);
                            loader.setAttribute("hidden", "");
                        })
                        .fail(er => { loader.setAttribute("hidden", ""); });
                }
            });
        });
    }

    if (searchButton) {
        //submit filters form while ignoring empty query parameters
        searchButton.addEventListener('click', function (e) {

            let categoryDropdown = document.querySelector('#select-category');
            let yearDropdown = document.querySelector('#year-picker');

            if (categoryDropdown.value == null || categoryDropdown.value == '')
                categoryDropdown.setAttribute('disabled', '');

            if (yearDropdown.value == null || yearDropdown.value == '')
                yearDropdown.setAttribute('disabled', '');

            document.querySelector('.filters-form').submit();
        });
    }
});

function storeToUrl(data) {
    window.history.pushState("", "", formUrlQuery(data));
}

function formUrlQuery(data) {
    return '?' + Object.keys(data).filter(key => data[key]).map(key => key + '=' + data[key]).join('&');
}

function SetCategoriesDropdown() {
    let urlParameterCategory = new URL(location.href).searchParams.get("category");
    let dropdown = document.querySelector('#select-category');

    if (dropdown != null) {
        let options = Array.from(dropdown.options);
        let optionToSelect = options.find(item => item.value == urlParameterCategory);

        if (optionToSelect)
            optionToSelect.selected = true;
    }
}

function SetYearsDropdown() {
    let urlParameterYear = new URL(location.href).searchParams.get("year");
    let dropdown = document.querySelector('#year-picker');

    if (dropdown != null) {
        let options = Array.from(dropdown.options);
        let optionToSelect = options.find(item => item.value == urlParameterYear);

        if (optionToSelect)
            optionToSelect.selected = true;
    }
}