document.addEventListener('DOMContentLoaded', () => {
    let contentVideo = document.querySelectorAll('.js-content-video');

    if(contentVideo.length > 0) {
        contentVideo.forEach((item) => {
            let bgIMage = item.querySelector('.background-video-image');
            let htmlVideo = item.querySelector('video');
            let iframeVideo = item.querySelector('iframe');

            bgIMage.addEventListener('click', function(){
                bgIMage.classList.add('hide');
                
                if (htmlVideo) {
                    htmlVideo.play(); 
                } else if (iframeVideo) {
                    iframeVideo.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*'); //?enablejsapi=1 !!!!
                }
            });
        });
    }
});
