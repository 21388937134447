import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
    const searchPagination = document.querySelector('.js-search-pagination');

    //on click, check the global Pagination.page value
    if (searchPagination) {

        //let buttons = document.querySelectorAll('.pagination-button, .pagination-list a');
        let loader = document.querySelector('#loader');
        let buttons = document.querySelectorAll('.pagination-button, .pagination-list');
        buttons.forEach(button => {
            button.addEventListener('click', function (e) {
                let urlParameterPage = new URL(location.href).searchParams.get("page");
                let urlParameterQuery = new URL(location.href).searchParams.get("query");

                if (urlParameterPage != Pagination.page) {
                    loader.removeAttribute("hidden");

                    $.ajax({
                        type: 'GET',
                        url: '/api/feature/eurobankaedak/search/searchresults',
                        data: { query: urlParameterQuery, page: Pagination.page },
                        contentType: 'application/json; charset=utf-8',
                    })
                        .done(resp => {
                            let dataForUrl = {
                                query: urlParameterQuery,
                                page: Pagination.page,
                            };
                            storeToUrl(dataForUrl);

                            $('.search-results-wrapper').html(resp);
                            loader.setAttribute("hidden", "");

                        })
                        .fail(er => { loader.setAttribute("hidden", ""); });
                }
            });
        });
    }
});

function storeToUrl(data) {
    window.history.pushState("", "", formUrlQuery(data));
}

function formUrlQuery(data) {
    return '?' + Object.keys(data).filter(key => data[key]).map(key => key + '=' + data[key]).join('&');
}