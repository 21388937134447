import $ from 'jquery';
import slick from 'slick-carousel';

$(function(){
    $('#main-banner-slider').slick({
        arrows: true,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.main-banner-navigation .slick-prev'),
        nextArrow: $('.main-banner-navigation .slick-next'),
        responsive: [
            {
                breakpoint: 1330,
                settings: {
                    arrows: false
                }
            }
        ]
    });

    let initialSettings = {
        arrows: true,
        dots: true,
        infinite: true,
        variableWidth: true,
        rows: 0,
        slide: '.js-card-slide',
        slidesToShow: 2,
        slidesToScroll: 2,
        customPaging: function(slick,index) {
            return customPagination(slick, index, 2);
        },
    }

    $('.cards-slider-only-mobile').slick({
        ...initialSettings,
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    infinite: false,
                    customPaging: function(slick,index) {
                        return customPagination(slick, index, 2);
                    }
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    infinite: false,
                    customPaging: function(slick,index) {
                        return customPagination(slick, index, 1);
                    }
                }
            }
        ]
    });

    $('.cards-slider:not(.cards-slider-products)').slick({
        ...initialSettings,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    customPaging: function(slick,index) {
                        return customPagination(slick, index, 3);
                    }
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    infinite: false,
                    customPaging: function(slick,index) {
                        return customPagination(slick, index, 2);
                    }
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    infinite: false,
                    customPaging: function(slick,index) {
                        return customPagination(slick, index, 1);
                    }
                }
            }
        ]
    });

    $('.image-video-gallery').slick({
        arrows: true,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        rows: 0,
        slide: '.gallery-item',
        customPaging: function(slick,index) {
            return customPagination(slick, index, 1);
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    customPaging: function(slick,index) {
                        return customPagination(slick, index, 2);
                    }
                }
            },
            {
                breakpoint: 630,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    customPaging: function(slick,index) {
                        return customPagination(slick, index, 1);
                    }
                }
            }
        ]
    });

    $('.table-slider').slick({
        arrows: true,
        dots: true,
        customPaging: function(slick,index) {
            return customPagination(slick, index, 1);
        },
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    //$('.cards-slider-products').slick({
    //    ...initialSettings,
    //    responsive: [
    //        {
    //            breakpoint: 9999,
    //            settings: {
    //                slidesToShow: 1,
    //                slidesToScroll: 1,
    //                customPaging: function (slick, index) {
    //                    return customPagination(slick, index, 1);
    //                }
    //            }
    //        },
    //    ]
    //});
});

let stringLabel = $('.cards-slider-only-mobile, .cards-slider').data('string');

let customPagination = (slick, index, slidesToShow) => {
    let num = (index + 1) * slidesToShow;
    let fixNum = (num > slick.slideCount) ? slick.slideCount : num;

    return '<span>' + fixNum + ' ' + stringLabel + ' ' +  slick.slideCount + '</span>';
}