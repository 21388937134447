document.addEventListener('DOMContentLoaded', () => {
    let audioContainer = document.querySelectorAll('.audio-player-container');
    let playState = 'play';
    let raf = null;

    if (audioContainer.length > 0) {
        audioContainer.forEach((item) => {
            const audio = item.querySelector('.audio-file');

            //audio details
            let currentTime = item.querySelector('.audio-current-time');
            let audioDuration = item.querySelector('.audio-duration');
            let audioSlider = item.querySelector('.audio-slider');
            let trackSlider = item.querySelector('.audio-track-slider');

            audio.addEventListener('canplay', function() {
                displayDuration(audioDuration, audio);
                setSliderMax(audioSlider, audio);
            });

            audioSlider.addEventListener('input', (event) => {
                audio.currentTime = audioSlider.value;
                updateControls(currentTime, audioSlider, trackSlider);
            });

            audio.addEventListener('timeupdate', () => {
                audioSlider.value = Math.floor(audio.currentTime);
                updateControls(currentTime, audioSlider, trackSlider);
            });

            //audio controls
            let audioIcon = item.querySelector('.audio-icon');

            audioIcon.addEventListener('click', function () {
                if(playState === 'play') {
                    audio.play();
                    audioIcon.classList.remove('play-icon');
                    audioIcon.classList.add('pause-icon');
                    playState = 'pause';
                } else {
                    audio.pause();
                    audioIcon.classList.add('play-icon');
                    audioIcon.classList.remove('pause-icon');
                    playState = 'play';
                }
            });

            audio.addEventListener("ended", function() {
                audioIcon.classList.add('play-icon');
                audioIcon.classList.remove('pause-icon');
                playState = 'play';
            });
        });
    }

    let displayDuration = (audioDuration, audio) => {
        audioDuration.textContent = calculateTime(audio.duration);
    }

    let calculateTime = (secs) => {
        let minutes = Math.floor(secs / 60);
        let seconds = Math.floor(secs % 60);
        let returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        return `${minutes}:${returnedSeconds}`;
    }

    let setSliderMax = (audioSlider, audio) => {
        audioSlider.max = Math.floor(audio.duration);
    }

    let updateControls = (currentTime, audioSlider, trackSlider) => {
        currentTime.textContent = calculateTime(audioSlider.value);
        trackSlider.style.setProperty('width', `${audioSlider.value / audioSlider.max * 100}%`);
    }   
});