document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.header')
    const navbar = document.querySelector('.header-main')
    const sticky = navbar.offsetTop

    window.onscroll = () => {
        if (window.pageYOffset > sticky) {
            navbar.classList.add("sticky")
            header.style.paddingBottom = navbar.offsetHeight + "px"
        } else {
            navbar.classList.remove("sticky")
            header.style.paddingBottom = "0"
        }
    }

    //search fund
//     const resultsWrapper = document.querySelector('.results-wrapper')
//     const tabs = document.querySelector('.tabs-content')

//     if(tabs) {
//         window.onscroll = () => {
//             if (window.pageYOffset > tabs) {
//                 navbar.classList.add("sticky")
//             } else {
//                 navbar.classList.remove("sticky")
//             }
//         }
//     }
})