import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
    const tabsWrapper = document.querySelector('.tabs-wrapper');
    const shareSelect = document.getElementById('select-share');

    if (tabsWrapper) {
        const tabTitle = tabsWrapper.querySelectorAll('.tab-title');

        if (tabTitle.length > 0) {
            tabTitle.forEach((item) => {
                item.addEventListener('click', function (e) {
                    const activeTab = tabsWrapper.querySelector('.tab-title.active');
                    const activeContent = tabsWrapper.querySelector('.tab-content.active');
                    const target = item.dataset.target;

                    activeTab.classList.remove('active');
                    e.target.classList.add('active');

                    activeContent.classList.remove('active');
                    document.getElementById(target).classList.add('active');
                });
            });
        }
    }

    if (shareSelect) {
        shareSelect.addEventListener('change', function () {
            let shareID = this.value;

            Promise.allSettled(
                [AsyncShareDetails(shareID),
                AsyncReturnTab(shareID),
                AsyncCommissionTab(shareID),
                AsyncInvestmentTab(shareID),
                AsyncMorningstarImage(shareID)]
            )
                .then(function (
                    [shareDetailsResult,
                        returnTabResults,
                        commissionTabResults,
                        investmentTabResults,
                        morningstarImageResults]) {
                    if (shareDetailsResult.value)
                        $('.share-details').html(shareDetailsResult.value);

                    if (returnTabResults.value)
                        $('.return-tab').html(returnTabResults.value);

                    if (commissionTabResults.value)
                        $('.commission-tab').html(commissionTabResults.value);

                    if (investmentTabResults.value)
                        $('.investment-tab').html(investmentTabResults.value);

                    if (morningstarImageResults.value)
                        $('.morningstar-logo').html(morningstarImageResults.value);
                });
        });
    }    

    function AsyncShareDetails(shareID) {
        return $.ajax({
            type: 'GET',
            url: '/api/feature/eurobankaedak/funds/sharedetails?shareID=' + shareID,
            contentType: 'application/json; charset=utf-8',
            async: true
        });
    }

    function AsyncReturnTab(shareID) {
        return $.ajax({
            type: 'GET',
            url: '/api/feature/eurobankaedak/funds/returntab?shareID=' + shareID,
            contentType: 'application/json; charset=utf-8',
            async: true
        });
    }

    function AsyncCommissionTab(shareID) {
        return $.ajax({
            type: 'GET',
            url: '/api/feature/eurobankaedak/funds/commissiontab?shareID=' + shareID,
            contentType: 'application/json; charset=utf-8',
            async: true
        });
    }

    function AsyncInvestmentTab(shareID) {
        return $.ajax({
            type: 'GET',
            url: '/api/feature/eurobankaedak/funds/investmenttab?shareID=' + shareID,
            contentType: 'application/json; charset=utf-8',
            async: true
        });
    }

    function AsyncMorningstarImage(shareID) {
        return $.ajax({
            type: 'GET',
            url: '/api/feature/eurobankaedak/funds/morningstarimage?shareID=' + shareID,
            contentType: 'application/json; charset=utf-8',
            async: true
        });
    }
});