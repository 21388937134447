document.addEventListener('DOMContentLoaded', () => {
    const socialSection = document.querySelector('.social-icons-sections');

    if (socialSection) {
        let togglers = document.querySelectorAll('.social-icons-toggle');
        Array.from(togglers).forEach(toggler => {
            toggler.addEventListener('click', function (e) {
                e.preventDefault();
                let parentElement = e.currentTarget.parentElement;

                if (parentElement) {
                    if (parentElement.classList.contains('active')) {
                        parentElement.classList.remove('active');
                    }
                    else {
                        parentElement.classList.add('active');
                    }
                }
            });
        });
    }
});