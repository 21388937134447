document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.hr-list')) {
        let personCard = document.querySelectorAll('.person-card');

        let modalCloseButtons = document.querySelectorAll('.custom-modal-background, .modal-close-button');
        //let modal = document.querySelector('.custom-modal');

        let htmlTag = document.querySelector('html');

        if(personCard.length > 0) {
            personCard.forEach((item) => {
                let cardButton = item.querySelector('.button');

                if(cardButton) {
                    cardButton.addEventListener('click', function(event){
                        htmlTag.classList.add('modal-opened');
                        let dataModalId = event.target.getAttribute('data-modal-id');
                        document.querySelector(`div[data-modal-id='${dataModalId}']`).style.display = 'block';
                        event.preventDefault ? event.preventDefault() : (event.returnValue = false);
                    });
                }
            });
        }

        if(modalCloseButtons.length > 0) {
            modalCloseButtons.forEach((item) => {
                item.addEventListener('click', function (event) {
                    htmlTag.classList.remove('modal-opened');

                    document.querySelectorAll('.custom-modal').forEach((item) => {
                        item.style.display = 'none';
                    });
                });
            });
        }

        let slickArrows = document.querySelectorAll('.slick-arrow');
        if (slickArrows.length > 0) {
            slickArrows.forEach((item) => {
                item.addEventListener('click', function (event) {
                    document.querySelectorAll('.custom-modal').forEach((item) => {
                        item.style.display = 'none';
                    });
                    let dataModalId = event.target.getAttribute('data-modal-destination-id');
                    document.querySelector(`div[data-modal-id='${dataModalId}']`).style.display = 'block';
                });
            });
        }
    }
});