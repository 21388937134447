document.addEventListener('DOMContentLoaded', () => {
    // Get all "navbar-burger" elements
    const jQuerynavbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if (jQuerynavbarBurgers.length > 0) {

        // Add a click event on each of them
        jQuerynavbarBurgers.forEach( el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const jQuerytarget = document.getElementById(target);
                const jQueryhtml = document.getElementsByTagName('html')[0];
                const jQueryheader = document.getElementsByTagName('header')[0];

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                jQuerytarget.classList.toggle('is-active');
                jQueryhtml.classList.toggle('is-used');
                jQueryheader.classList.toggle('menu-opened');
            });
        });
    }
});